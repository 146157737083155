<template>
    <div>
        <CRow>
            <CCol lg="8">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="spell-check" /> New Prefix</CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="8">
                                <CInput 
                                    :lazy="false"
                                    :value.sync="$v.data.prefix.$model"
                                    :isValid="checkIfValid($v.data, 'prefix')"
                                    label="Prefix" 
                                    type="text" 
                                    placeholder="Sample Prefix" 
                                    autocomplete="off"  
                                    v-model="data.prefix"
                                    invalidFeedback="Must be more than three(3) characters."
                                />
                            </CCol>
                        </CRow>
                        
                        <CRow>
                            <!--  -->
                            <CCol col="12" class="text-right">
                                <CButton 
                                    :disabled="!isValid"
                                    shape="pill" 
                                    color="primary"
                                    @click="addNewSource()"
                                >
                                    <font-awesome-icon icon="save"/> Save
                                </CButton>
                            </CCol>
                        </CRow>

                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import { required, minLength} from "vuelidate/lib/validators"
export default {
    name:'PrefixCreate',
    data(){ 
        return {
            data: {
                prefix: null
            }
        }
    },
    created() {
        this.$emit('activeTab', 1);
    },
    computed: {
        isValid () { return !this.$v.data.$invalid },
        isDirty () { return this.$v.data.$anyDirty },
    },
    
    validations: { // FORM Validation (Vue)
        data: {
            prefix: {
                required, minLength: minLength(3)
            },
        }
    },
    methods: {
        addNewSource: function () {
            this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to create new prefix.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        this.$Progress.start()
                        return axios.post('/form-managements/prefix/store', this.data, {validateStatus: () => true}).then(response => {
                            if ( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    text: `${this.data.prefix} has been added.`,
                                })
                                this.data.prefix = null
                                this.$v.data.$reset()
                                this.$v.data.$touch()
                                this.$Progress.finish()
                            }
                        })
                    }
                }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
            return;
        }
    }
}
</script>

<style>

</style>